<template>
  <div class="repair-info-wrapper">
    <div class="title-box">
      <Title title="基础信息" />
    </div>
    <div class="header-box">
      <StatusHeader :detailData="detailData" />
    </div>
    <div class="title-box">
      <Title title="故障信息" />
    </div>
    <div class="fault-content-wrapper">
      <div class="item-box">
        <div class="title">故障等级：</div>
        <div class="content">{{ detailData.faultLevelName }}</div>
      </div>
      <div class="item-box">
        <div class="title">故障原因：</div>
        <div class="content">{{ detailData.faultReason }}</div>
      </div>
      <div class="item-box">
        <div class="title">维修内容：</div>
        <div class="content">{{ detailData.repairContent }}</div>
      </div>
      <div class="item-box">
        <div class="title">维修照片：</div>
        <div class="content">
          <span
            class="img-box"
            v-for="(item, index) of detailData.faultPhoto"
            :key="'pho' + index"
          >
            <img :src="imgUrl + item" alt="" />
          </span>
        </div>
      </div>
      <div class="item-box">
        <div class="title">维修时间：</div>
        <div class="content">
          {{ detailData.repairStartTime }} 至 {{ detailData.repairEndTime }}
        </div>
      </div>
      <div class="item-box">
        <div class="title">维修人员：</div>
        <div class="content">{{ detailData.realRepairUserName }}</div>
      </div>
      <div class="item-box" v-show="detailData.repairStatus !== '待验收'">
        <div class="title">验收人员：</div>
        <div class="content">{{ detailData.receiveUserName }}</div>
      </div>
      <div class="item-box" v-show="detailData.repairStatus !== '待验收'">
        <div class="title">验收时间：</div>
        <div class="content">{{ detailData.receiveTime }}</div>
      </div>
      <div class="item-box">
        <div class="title">验收状态：</div>
        <div class="content">{{ detailData.faultStatusName }}</div>
      </div>
      <div class="item-box" v-show="detailData.repairStatus === '待验收'">
        <div class="title">扫码验收：</div>
        <div class="content">
          <div class="qrcode-box" id="qrcode"></div>
        </div>
      </div>
      <div
        class="tab-bar-box"
        v-if="
          $route.query &&
          $route.query.show == 'true' &&
          detailData.faultStatus == 3
        "
      >
        <span class="left" @click="postApprovalFault(5)">拒绝</span>
        <span class="right" @click="postApprovalFault(4)">同意</span>
      </div>
    </div>
    <!--识别结果-->
    <van-dialog
      v-model="showIdentityResult"
      title="识别结果"
      show-cancel-button
      confirmButtonText="验收"
      cancelButtonText="拒绝"
      @confirm="confirm"
      @cancel="cancel"
    >
      <p style="text-align: center">
        是否验收{{ detailData.deviceName }}的维修任务
      </p>
    </van-dialog>
    <!--验收结果-->
    <van-dialog
      v-model="showApproveResult"
      title="验收结果"
      confirmButtonText="知道了"
      @confirm="confirm1"
    >
      <p style="text-align: center">{{ approveResult }}</p>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast } from 'vant'
import Title from '@/components/Title'
import QRCode from 'qrcodejs2'
import StatusHeader from './components/StatusHeader'
import { getDeviceRepairDetail, postRepairApproval } from '@/api/deviceRepair'
import { imgUrl } from '@/config/env'
import { postApprovalFault } from '@/api/approval'
Vue.use(Toast)

Vue.use(Dialog)
export default {
  name: 'RepairStatus',
  components: {
    Title,
    StatusHeader,
  },
  data() {
    return {
      showIdentityResult: false,
      showApproveResult: false,
      approveResult: '',
      detailData: {},
      imgUrl,
      repairId: '',
    }
  },
  mounted() {
    const id = this.$route.query.id
    this.getDeviceRepairDetail(id)
    this.$nextTick(function () {
      this.qrcode(id)
    })

    const repairId = this.$route.query.repairId

    if (repairId) {
      this.repairId = repairId
      this.showIdentityResult = true
    }
  },
  methods: {
    //  生成二维码
    qrcode(id) {
      const link = location.href + '&repairId=' + id
      new QRCode('qrcode', {
        width: 80,
        height: 80, // 高度
        text: link, // 二维码内容
      })
    },
    /**
     * 获取详情
     */
    getDeviceRepairDetail(id) {
      getDeviceRepairDetail(id).then((res) => {
        this.detailData = res.details
        switch (this.detailData.repairStatus) {
          case -1:
            this.detailData.repairStatus = '已撤销'
            break
          case 0:
            this.detailData.repairStatus = '待验收'
            break
          case 1:
            this.detailData.repairStatus = '维修中'
            break
          case 2:
            this.detailData.repairStatus = '验收不通过'
            break
          case 3:
            this.detailData.repairStatus = '验收通过'
            break
        }
        let faultPhoto = []
        this.detailData.faultPhoto.split(',').forEach((item) => {
          faultPhoto.push(item)
        })
        this.detailData.faultPhoto = faultPhoto
      })
    },
    /**
     * 验收
     */
    confirm() {
      postRepairApproval(this.repairId, 1).then((res) => {
        this.approveResult = res.retMsg
        this.showApproveResult = true
      })
    },
    /**
     * 验收结果
     */
    confirm1() {
      this.$router.push('/')
    },
    /**
     * 拒绝
     */
    cancel() {
      postRepairApproval(this.repairId, 2).then((res) => {
        this.approveResult = res.retMsg
        this.showApproveResult = true
      })
    },

    postApprovalFault(approvalFlag) {
      const orderId = this.$route.query.id
      postApprovalFault(orderId, approvalFlag).then((res) => {
        if (res.retCode === 1) {
          Toast.success(res.retMsg)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          Toast.fail(res.retMsg)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.repair-info-wrapper {
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  position: relative;
  /deep/.van-dialog {
    border-radius: 0.2rem;
    .van-dialog__header {
      padding-top: 1rem;
      font-size: 0.8rem;
    }
    .van-dialog__content {
      margin: 1rem 0;
      p {
        font-size: 0.75rem;
      }
    }
    .van-button {
      font-size: 0.8rem;
      span {
        color: #1989fa;
      }
    }
  }
  .title-box {
    box-sizing: border-box;
    padding: 0 1rem;
  }
  .fault-content-wrapper {
    background-color: white;
    box-sizing: border-box;
    padding: 1rem;
    .item-box {
      display: flex;
      margin-bottom: 0.5rem;
      line-height: 1.2rem;
      .title {
        font-size: 0.7rem;
        color: #333333;
        width: 4rem;
      }
      .content {
        flex: 1;
        font-size: 0.7rem;
        color: #999999;
        span.img-box {
          @include wh(2.5rem, 2.5rem);
          background-color: #f1f1f1;
          display: inline-block;
          margin-right: 0.3rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .qrcode-box {
          @include wh(4rem, 4rem);
          background-color: #f1f1f1;
          img {
            @include wh(4rem, 4rem);
          }
        }
      }
    }
  }
  .tab-bar-box {
    @include wh(100%, 2.3rem);
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: block;
      height: 100%;
      font-size: 0.7rem;
      @include CC;
    }
    span.left {
      width: 50%;
      color: #666666;
    }
    span.right {
      width: 50%;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(79, 147, 255, 1) 0%,
        rgba(58, 187, 253, 1) 100%
      );
    }
  }
}
</style>
